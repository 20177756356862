a {
	text-decoration: none;
}

body {
	font-family: 'Montserrat', sans-serif;
}

pre {
	font-size: 16px;
	/* Defina o tamanho da fonte desejado */
}


* {
	font-size: 98%;
}

.special_modal .modal-content {
	background-color: #1e2129;
	color: rgb(235, 238, 237);
	border-radius: 10px;
}

.custom-modal-title {
    font-size: 16px; /* Defina o tamanho da fonte desejado */
    /* Outros estilos personalizados, se necessário */
}

.custom-modal-header {
    padding: 0.7rem; /* Adjust the padding as needed */
}

.custom-modal-footer {
    padding: 0.5rem; /* Adjust the padding as needed */
	
}

.custom-modal {
    max-width: 400px; /* ou qualquer valor desejado */
    width: 100%;
}
.modal-header-atendente {
	background-color: #1e2129;
}

.special_Conexao .modal-content {
	background-color: #fafafa;
	color: rgb(10, 10, 10);
	border-radius: 10px;
}

.btn-sm {
	font-size: 11px;
	padding: 5px 11px;
}

.center {
	margin-left: auto;
	margin-right: auto;
}

.center2 {
	margin-top: 15px;
	margin-left: 35px;
	margin-right: 35px;
}

/*footer*/
.col_white_amrc {
	color: #FFF;
}

footer {
	width: 100%;
	background-color: #263238;
	min-height: 250px;
	padding: 10px 0px 25px 0px;
}

.pt2 {
	padding-top: 40px;
	margin-bottom: 20px;
}

footer p {
	font-size: 13px;
	color: #CCC;
	padding-bottom: 0px;
	margin-bottom: 8px;
}

.mb10 {
	padding-bottom: 15px;
}

.footer_ul_amrc {
	margin: 0px;
	list-style-type: none;
	font-size: 14px;
	padding: 0px 0px 10px 0px;
}

.footer_ul_amrc li {
	padding: 0px 0px 5px 0px;
}

.footer_ul_amrc li a {
	color: #CCC;
}

.footer_ul_amrc li a:hover {
	color: #fff;
	text-decoration: none;
}

.fleft {
	float: left;
}

.padding-right {
	padding-right: 10px;
}

.footer_ul2_amrc {
	margin: 0px;
	list-style-type: none;
	padding: 0px;
}

.footer_ul2_amrc li p {
	display: table;
}

.footer_ul2_amrc li a:hover {
	text-decoration: none;
}

.footer_ul2_amrc li i {
	margin-top: 5px;
}

.bottom_border {
	border-bottom: 1px solid #323f45;
	padding-bottom: 20px;
}

.foote_bottom_ul_amrc {
	list-style-type: none;
	padding: 0px;
	display: table;
	margin-top: 10px;
	margin-right: auto;
	margin-bottom: 10px;
	margin-left: auto;
}

.foote_bottom_ul_amrc li {
	display: inline;
}

.foote_bottom_ul_amrc li a {
	color: #999;
	margin: 0 12px;
}

.social_footer_ul {
	display: table;
	margin: 15px auto 0 auto;
	list-style-type: none;
}

.social_footer_ul li {
	padding-left: 20px;
	padding-top: 10px;
	float: left;
}

.social_footer_ul li a {
	color: #CCC;
	border: 1px solid #CCC;
	padding: 8px;
	border-radius: 50%;
}

.social_footer_ul li i {
	width: 20px;
	height: 20px;
	text-align: center;
}

.link {
	color: #000;
}


.chat-box {
	padding: 10px;
	height: 350px;
	overflow-y: auto;
}

.message {
	margin-bottom: 10px;
	padding: 5px;
	border-radius: 5px;
	word-wrap: break-word;
	display: block;
	/* Exibe cada mensagem em uma nova linha */
	overflow-wrap: break-word;
	word-break: break-word;
	white-space: normal;
	clear: both;
}

.message.user {
	background-color: #007bff;
	color: #fffdfd;
	text-align: right;
	width: fit-content;
	float: right;
}

.message.bot {
	background-color: #b4b3b3;
	color: #000000;
	width: fit-content;
}


/* Estilos para o Navbar */
.navbar {
	background-color: #007bff;
	/* Azul primário */
}

.navbar-brand {
	color: #fff;
	/* Texto branco */
}

.navbar-nav .nav-link {
	color: #fff;
	/* Texto branco */
}

/* Estilos para os botões */
.btn-primary {
	background-color: #007bff;
	/* Azul primário */
	border-color: #007bff;
	/* Azul primário */
	font-size: 12px;
	/* Tamanho de fonte menor */
	padding: 4px 10px;
	/* Padding menor */
}

.btn-primary:hover {
	background-color: #0056b3;
	/* Azul mais escuro ao passar o mouse */
	border-color: #0056b3;
	/* Azul mais escuro ao passar o mouse */
}


/* Botões secundários */
.btn-secondary {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de sucesso */
.btn-success {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de informação */
.btn-info {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de aviso */
.btn-warning {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de perigo */
.btn-danger {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de claro */
.btn-light {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de escuro */
.btn-dark {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botões de link */
.btn-link {
	font-size: 12px;
	padding: 4px 10px;
}

/* Botão flutuante */
.container-flutuante {
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 9999;
}

.floating-avatar {
	position: relative;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.online-indicator {
	position: absolute;
	top: 46px;
	right: -11px;
	width: 12px;
	height: 12px;
	background-color: rgb(19, 197, 19);
	border-radius: 50%;
	pointer-events: none;
  }






/* Avatar chat */
.avatar-container {
	display: flex;
	align-items: center;
}

.avatar-container .text-right {
	font-size: 12px;
	margin-left: 8px;
}

.avatar-container .avatar {
	margin-right: 8px;
}

.avatar-container>div>div {
	margin-top: 4px;
	/* Adicione um espaçamento entre os textos */
}

.text-white {
	color: white;
}

.font-stylish {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	/* Defina o tamanho da fonte desejado */
	font-weight: bold;
	/* Adicione a espessura da fonte desejada */
}


.close-button {
	background-color: transparent;
	border: none;
	color: white;
	font-size: 34px;
	/* Ajuste o tamanho da fonte para um valor maior */
	position: relative;
	display: inline-block;
	width: 30px;
	/* Ajuste o tamanho da largura */
	height: 45px;
	/* Ajuste o tamanho da altura */
}

.centered-text {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 11px; /* Ajuste o valor do tamanho da fonte conforme necessário */
  }

  .chat-container {
	background-image: url('https://wallpapers.com/images/high/whatsapp-chat-warm-gray-n0gvicfob8j7k3ud.webp');
	background-size: cover;
	background-repeat: no-repeat;
  }

  .modal-content-custom {
	padding: 0;
  }

  .image-preview {
	max-width: 25%;
	max-height: 25%;
	width: auto;
	height: auto;
  }
