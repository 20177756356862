.youtube-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Proporção 16:9 (altura / largura) */
    position: relative;
    overflow: hidden;
  }
  
  .youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  