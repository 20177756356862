.message-bubble {
    position: absolute;
    top: 50%;
    left: -210px;
    transform: translateY(-50%);
    background-color: #d8d8d8;
    color: #000000;
    padding: 10px;
    border-radius: 8px;
    max-width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  